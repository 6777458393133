import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["favicon"]

  initialize() {
    this.darkModeMediaQuery = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)")
  }

  connect() {
    this.setFavicon()
    this.darkModeMediaQuery.addEventListener("change", this.setFavicon.bind(this))
    document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this), false)
  }

  handleVisibilityChange() {
    if (document.visibilityState === "visible") this.setFavicon()
  }

  setFavicon() {
    const darkModeOn = this.darkModeMediaQuery.matches

    if (darkModeOn) {
      this._setIcon("dark")
    } else {
      this._setIcon("light")
    }
  }

  _setIcon(mode) {
    this.faviconTargets.forEach(favicon => {
      favicon.href = favicon.dataset[mode]
    })
  }
}
