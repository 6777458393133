import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openIntercom(event) {
    if (typeof Intercom !== "undefined") {
      event.preventDefault()
      Intercom("show")
    }
  }
}
