import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "current"]

  toggle() {
    if (this.buttonTarget.getAttribute("aria-expanded") == "false") {
      this.show()
    } else {
      this.hide(null)
    }
  }

  show() {
    this.buttonTarget.setAttribute("aria-expanded", "true")
  }

  hide(event) {
    if (event && (this.buttonTarget.contains(event.target))) {
      return
    }

    this.buttonTarget.setAttribute("aria-expanded", "false")
  }

  change(event) {
    this.currentTarget.textContent = event.currentTarget.textContent
  }
}
