import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]
  prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)")
  html = document.querySelector("html")

  initialize() {
    this.setToggleState(this.getCurrentTheme())
    this.watchSystemChanges()
  }

  watchSystemChanges() {
    this.prefersDarkScheme.addEventListener("change", e => {
      const newColorScheme = e.matches ? "dark" : "light"

      if (this.getCurrentTheme() != newColorScheme) {
        this.toggleTheme()
      }
    })
  }

  setToggleState(theme) {
    if (theme === "dark") {
      this.toggleTarget.setAttribute("checked", "checked")
    } else {
      this.toggleTarget.removeAttribute("checked")
    }
  }

  getCurrentTheme() {
    return localStorage.getItem("theme") || (
      this.html.classList.contains("dark-theme") ? "dark" : "light"
    )
  }

  toggleTheme() {
    this.html.classList.toggle("dark-theme")
    const theme = this.html.classList.contains("dark-theme") ? "dark" : "light"
    this.setToggleState(theme)
    localStorage.setItem("theme", theme)
  }
}
