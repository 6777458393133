import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["closeButton", "popover", "toggleButton", "openButton"]

  toggle() {
    this.popoverTarget.toggleAttribute("hidden")
  }

  close(event) {
    if (this.toggleButtonTarget.contains(event.target) || this.popoverTarget.hasAttribute("hidden") || this.openButtonTargets.includes(event.target)) {
      return false
    }
    if ((this.closeButtonTargets.includes(event.target)) || (!this.popoverTarget.contains(event.target))) {
      this.popoverTarget.setAttribute("hidden", "")
    }
  }
}
