import { Controller } from "@hotwired/stimulus"

const consentCookieName = "bump_cookie_consent"
const fixedCookieNames = {
  "Bump.sh": ["bump_marketing_tracking"],
  "Intercom": ["intercom-"],
  "Segment": ["ajs_user_id", "ajs_anonymous_id"]
}

export default class extends Controller {
  static targets = ["banner"]

  show() {
    this.bannerTarget.setAttribute("aria-hidden", false)
  }

  decline() {
    this.#setCookie(consentCookieName, false)
    const cookies = this.#getAllCookies()
    const cookiesToRemove = Object.values(fixedCookieNames).flat()

    Object.keys(cookies).forEach(cookieName => {
      cookiesToRemove.find(cookieNameToRemove => {
        if (cookieName.startsWith(cookieNameToRemove)) {
          this.#deleteCookie(cookieName)
          return
        }
      })
    })
    location.reload()
  }

  accept() {
    this.#setCookie(consentCookieName, true)
    this.bannerTarget.setAttribute("aria-hidden", true)
  }

  #setCookie(name, value) {
    document.cookie = `${name}=${value};Path=/;Expires=${this.#getNextYear().toUTCString()};SameSite=Strict"`
  }

  #deleteCookie(name) {
    this.#getDomains().forEach(domain => {
      document.cookie = `${name}=;Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=${domain}`
    })
  }

  #getAllCookies() {
    return Object.fromEntries(document.cookie.split("; ").map(c => c.split("=")))
  }

  #getNextYear() {
    return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  }

  #getDomains() {
    const domain = location.hostname
    const domains = [domain]

    // length == 1 is localhost so we don't need to do anything
    if (domain.split(".").length > 1) {
      // Add domain with leading '.' (hostOnly=false) if it's not present
      if (!domain.startsWith(".")) {
        domains.push(`.${domain}`)
      }
    }

    return domains
  }
}

export function checkNonRefusedConsentCookie() {
  return document.cookie.indexOf(`${consentCookieName}=false`) == -1
}
