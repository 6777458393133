import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.loadTypeformEmbedScript()
  }

  loadTypeformEmbedScript() {
    const script = document.createElement("script")
    script.src = "//embed.typeform.com/next/embed.js"
    script.async = true
    document.head.appendChild(script)
  }
}
