import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "toggleVisibility"]

  toggle() {
    let contentTarget = this.contentTarget
    let toggleVisibilityTargets = this.toggleVisibilityTargets

    toggleVisibilityTargets.forEach((element) =>{
      element.classList.toggle("hidden")
    })

    if (contentTarget.getAttribute("type") == "password") {
      contentTarget.type = "text"
    } else {
      contentTarget.type = "password"
    }
  }
}
