import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  link(e) {
    e.preventDefault()
    this.surroundSelectionWith("[", "](url)")
  }

  italic(e) {
    e.preventDefault()
    this.surroundSelectionWith("_", "_")
  }

  bold(e) {
    e.preventDefault()
    this.surroundSelectionWith("**", "**")
  }

  quote(e) {
    e.preventDefault()
    this.surroundSelectionWith("> ", "")
  }

  code(e) {
    e.preventDefault()
    this.surroundSelectionWith("`", "`")
  }

  surroundSelectionWith(before, after) {
    let textarea = this.element
    let selectedText

    if (textarea.selectionStart !== undefined) {
      var startPos = textarea.selectionStart
      var endPos = textarea.selectionEnd
      selectedText = textarea.value.substring(startPos, endPos)

      textarea.value = textarea.value.substring(0, startPos) +
        before + selectedText + after +
        textarea.value.substring(endPos, textarea.value.length)

      textarea.setSelectionRange(startPos + before.length, endPos + before.length)
    }
  }
}
