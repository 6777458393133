import {requestIdleCallback} from "global/javascript/request_idle_callback"
import {checkNonRefusedConsentCookie} from "controllers/cookies_controller.js"

document.addEventListener("DOMContentLoaded", () => {
  requestIdleCallback(() => {
    const internalUrl = document.querySelector("body").getAttribute("data-internal-url")

    // We check non-refusal instead of accepted cookie to avoid reloading the page if user clicks `accept all`
    if (checkNonRefusedConsentCookie()) {
      if (internalUrl) {
        window.fathom.trackPageview({ url: internalUrl })
      } else {
        window.fathom.trackPageview()
      }
    }
  })
})
