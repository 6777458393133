import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["action"]

  // to use in pair with stimulus-hotkeys, as the argument is not an "Event" object
  showFromHotkeys(dialogId) {
    const dialog = document.getElementById(dialogId)
    this.#showDialog(dialog)
  }

  show(event) {
    if (this.originalClick != true) {
      event.preventDefault()
      event.stopPropagation()
      this.#showDialog(document.getElementById(event.params.id))
    }
  }

  #showDialog(dialog) {
    dialog.showModal()
    this.dispatch("show")
  }

  close(event) {
    event.target.closest("dialog").close()
  }

  backdropClose(event) {
    if (event.target === event.currentTarget) {
      event.currentTarget.close()
    }
  }

  forceAction() {
    this.originalClick = true
    this.actionTarget.click()
  }
}
