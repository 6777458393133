import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    validationUrl: String
  }

  validateInput(event) {
    // Debounce validation and wait 600ms before sending XHR request
    if (this.handler) {
      clearTimeout(this.handler)
    }
    this.handler = setTimeout(() => this.doValidateInput(event), 600)
  }

  // Private functions

  doValidateInput(event) {
    Rails.ajax({
      type: "POST",
      url: this.validationUrlValue,
      data: Rails.serializeElement(this.element),
      success: () => this.renderAllValid(),
      error: response => this.errorHandler(event, response)
    })
  }

  renderAllValid() {
    this.element.querySelectorAll("input").forEach(e => {
      this.renderValid(e)
    })
  }

  renderValid(input) {
    input.parentElement.classList.remove("field_with_errors")
    input.parentElement.querySelectorAll("span.error").forEach(e => e.remove())
  }

  errorHandler(event, response) {
    const input = event.target

    // Cleanup any previous errors
    this.renderValid(input)

    // Set errors on target input
    if (response.errors) {
      const error = response.errors[this.getInputAttributeName(input)]
      this.renderError(input, error)
    }
  }

  getInputAttributeName(input) {
    const name = input.id.replace(/^user_/, "")

    // needed due to nested attribute (user.namespace.slug)
    if (name === "slug") {
      return "namespace.slug"
    } else {
      return name
    }
  }

  renderError(input, text) {
    if (text) {
      input.parentElement.classList.add("field_with_errors")
      input.insertAdjacentHTML("afterend", "<span class=\"error\">" + text + "</span>")
    }
  }
}
