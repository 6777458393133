import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { direction: String }

  connect() {
    this.lastScrollTop = 0
    this.scrolling = false
  }

  setScrollDirection() {
    const self = this
    const scrollTop = window.pageYOffset || self.element.scrollTop

    if (!this.scrolling) {
      window.requestAnimationFrame(() => {
        if (scrollTop > self.lastScrollTop) {
          self.directionValue = "down"
        } else if (scrollTop < self.lastScrollTop) {
          self.directionValue = "up"
        }
        self.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
        self.scrolling = false
      })
      self.scrolling = true
    }
  }
}
