import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "drawer"]

  toggleMenu(event) {
    const isToggled = this.element.getAttribute("data-toggled-menu")
    const target = event.target
    if (event.target === this.buttonTarget && isToggled !== "true") {
      this.element.setAttribute("data-toggled-menu", true)
    } else if (!this.drawerTarget.contains(target)) {
      this.element.setAttribute("data-toggled-menu", false)
    }
  }
}
