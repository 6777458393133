import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "copyButton"]

  copy() {
    let originalIcon = this.copyButtonTarget.innerHTML

    // We target the Value when it relates to a specific Input
    // otherwise we can simply access the content with innerText
    if (!this.contentTarget.innerText) {
      navigator.clipboard.writeText(this.contentTarget.value)
    } else {
      navigator.clipboard.writeText(this.contentTarget.innerText)
    }
    this.copyButtonTarget.innerHTML = "Copied"
    this.copyButtonTarget.setAttribute("disabled", true)

    setTimeout(() => {
      this.copyButtonTarget.innerHTML = originalIcon
      this.copyButtonTarget.removeAttribute("disabled")
    }, 2000)
  }
}
