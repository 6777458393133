import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    maxHeight: Number,
    link: String
  }

  connect() {
    this.buttonTemplate = document.querySelector("[data-show-more-target=\"button\"")
    this.#isCollapsed(this.element)
  }

  #isCollapsed(container) {
    container.style.height = "auto"
    container.classList.remove("collapsed")
    this.#removeButton(container)

    if (container.offsetHeight > this.maxHeightValue) {
      container.style.height = `${this.maxHeightValue}px`
      container.classList.add("collapsed")
      if (this.hasLinkValue) {
        this.#appendLink(container)
      } else {
        this.#appendButton(container)
      }
    }
  }

  #appendLink(container) {
    const link = document.createElement("a")
    link.href = this.linkValue
    link.classList.add("show-more-button")
    link.innerText = "Show More"
    container.appendChild(link)
  }

  #appendButton(container) {
    const button = document.createElement("button")
    button.classList.add("show-more-button")
    button.innerText = "Show More"
    button.addEventListener("click", this.showMore.bind(this))
    container.appendChild(button)
  }

  #removeButton(container) {
    const button = container.querySelector(".show-more-button")
    if (button) {
      button.remove()
    }
  }

  showMore() {
    this.element.style.height = "auto"
    this.element.classList.remove("collapsed")
    this.#removeButton(this.element)
  }
}
